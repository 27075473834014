export const environment = {
   production: false,
   version: '3.29.30',
   FirebaseFunctionUrlCloud: 'https://us-central1-omni2100.cloudfunctions.net',
   FirebaseFunctionUrlLocal: 'http://localhost:5000/omni2100/us-central1',
   googleAPIKey: 'AIzaSyA4PibHw2Uk57pc4iB_wwWcNFgqnnivV-g',
   googleAPIKey_tz: 'AIzaSyDU-WspyWCE-HODAYVGUXp-U4w6TK4XXeg',
   firebase: {
      apiKey: 'AIzaSyDV5tDZ2VVCb47eqoG-Qi6D7v1KLSzHW3s',
      authDomain: 'omni2100.firebaseapp.com',
      databaseURL: 'https://omni2100.firebaseio.com',
      projectId: 'omni2100',
      storageBucket: 'omni2100.appspot.com',
      messagingSenderId: 'omni2100.appspot.com',
   },
   APIS: {
      USERS: 'Users_Test',
      CUSTOMERPORTALS: 'CustomerPortals_Test',
      CUSTOMERS: 'Customers_Test',
      ZONES: 'Zones_Test',
      SENSORS: 'Sensors_Test',
      STAFFINFO: 'StaffInfo_Test',
      SENSORTYPES: 'SensorTypes_Test',
      SENSORMODELS: 'SensorModels_Test',
      SENSORDATA: 'SensorData_Test',
      SENSORDEVICES: 'SensorDevices_Test',
      SENSORCALIBRATION: 'SensorCalibration_Test',
      SENSORCONFIGS: 'SensorConfigs_Test',
      ANAYLYTICALDATA: 'AnalyticalData_Test',
      PROCESSEDDATA: 'ProcessedData_Test',
      SENSORCSVS: 'SensorCsvs_Test',
      RAWDATA: 'RawData_Test',
      RUN: 'Run_Test',
      DEBUGDATA: 'DebugData_Test',
      CONFIGURATIONS: 'Configurations_Test',
      CRONJOBTESTSENSOR: 'CronJobTestSensor_Test',
      CALIBRATIONDATA: 'CalibrationData_Test',
      CUSTOMERCONTACTGROUPS: 'CustomerContactGroups_Test',
   },
   Upload: {
      logoFolder: 'logo_Test',
      SENSORCOMFIGS: 'SensorConfigs_Test',
      PROCESSCSVS: 'ProcessCSVs_Test',
   },
   SystemType: 'test',
   API_BASE_URL: 'https://omni-api.omniscent.com',
   API_BASE_URL_EXT: '/v3/query'
};
